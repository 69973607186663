:root {
	font-size: 20px;
}

@media only screen and (max-width: 940px) {
	:root {
		font-size: 16px;
	}
}

* {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: transparent;
    border: 0 solid transparent;
    outline: 0;
    font-family: "Heebo", sans-serif;
    border-style: solid;
	box-sizing: border-box; /* Opera/IE 8+ */
}
